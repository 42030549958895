.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 2rem;
    background-color: var(--white-color);
    color: var(--primary-blue);
}

.message {
    margin-top: 30px;
    width: 100%;
    text-align: center;
}

.email {
    text-decoration: underline;
    color: var(--pink-color);
}