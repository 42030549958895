.container {
    position: absolute;
    top: 50px;
    right: 0px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    background-color: var(--primary-blue);
}

.navigationLink {
    font-size: 18px;
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    width: 200px;
}

.navigationLink:hover {
    border-bottom: var(--yellow-color) solid 3px;
    color: var(--yellow-color);
}