@media screen and (max-width: 399px) {
    .container {
        display: flex;
        background-color: var(--white-color);
        color: var(--white-color);
        justify-content: center;
        align-items: center;
        height: 340px;
        flex-direction: column;
    }
    .photo {
        height: 120px;
        width: 200px;
    }
    .sidePhoto {
        display: none;
    }
    .mobilePhotoContainer {
        display: none;
    }
    .mobilePhoto {
        height: 120px;
        width: 75px;
    }
}

@media screen and (min-width: 400px) and (max-width: 799px) {
    .container {
        display: flex;
        background-color: var(--white-color);
        color: var(--white-color);
        justify-content: center;
        align-items: center;
        height: 340px;
        flex-direction: column;
    }
    .photo {
        height: 180px;
        width: 400px;
    }
    .sidePhoto {
        display: none;
    }
    .mobilePhotoContainer {
        display: none;
    }
    .mobilePhoto {
        height: 140px;
        width: 125px;
    }
}

@media screen and (min-width: 800px) and (max-width: 1399px) {
    .container {
        display: flex;
        background-color: var(--white-color);
        color: var(--white-color);
        justify-content: center;
        align-items: flex-start;
        height: 220px;
    }
    .photo {
        height: 200px;
        width: 450px;
    }
    .sidePhoto {
        margin: auto;
        height: 200px;
        width: 150px;
    }
    .mobilePhotoContainer {
        display: none;
    }
    .mobilePhoto {
        display: none;
    }
}

@media screen and (min-width: 1400px) and (max-width: 1599px) {
    .container {
        display: flex;
        background-color: var(--white-color);
        color: var(--white-color);
        justify-content: center;
        align-items: flex-start;
        height: 380px;
    }
    .photo {
        height: 360px;
        width: 600px;
    }
    .sidePhoto {
        height: 360px;
        width: 400px;
    }
    .mobilePhotoContainer {
        display: none;
    }
    .mobilePhoto {
        display: none;
    }
}

@media screen and (min-width: 1600px) {
    .container {
        display: flex;
        background-color: var(--white-color);
        color: var(--white-color);
        justify-content: center;
        align-items: flex-start;
        height: 420px;
    }
    .photo {
        height: 400px;
        width: 740px;
    }
    .sidePhoto {
        height: 400px;
        width: 430px;
    }
    .mobilePhotoContainer {
        display: none;
    }
    .mobilePhoto {
        display: none;
    }
}