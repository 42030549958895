.container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 20px 2rem;
    background-color: var(--white-color);
    color: var(--primary-blue);
}

.title {
    margin-bottom: 20px;
}

.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex: 1;
}

.inputContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex: 1;
}

.emailContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex: 1;
}