@media screen and (min-width: 880px) {
    .container {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 20px 2rem;
        background-color: var(--white-color);
        background-size: cover;
        color: var(--primary-blue);
    }
    .title {
        margin-bottom: 20px;
    }
    .buttonContainer {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex: 1;
    }
    .inputContainer {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex: 1;
    }
    .emailContainer {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex: 1;
    }
    .cardElementContainer {
        padding: 10px 15%;
        font-size: 24px;
        color: var(--primary-blue);
    }
}

@media screen and (max-width: 880px) {
    .container {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 10px 2rem;
        background-color: var(--white-color);
        background-size: cover;
        color: var(--primary-blue);
    }
    .title {
        margin-bottom: 20px;
    }
    .buttonContainer {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex: 1;
    }
    .inputContainer {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
    }
    .emailContainer {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex: 1;
    }
    .cardElementContainer {
        padding: 10px 15%;
        font-size: 24px;
        color: var(--primary-blue);
    }
}