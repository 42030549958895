.container {
    background-color: var(--primary-blue);
    color: var(--white-color);
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    min-width: 200px;
    margin-bottom: 5px;
}

.imagesContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.quantityIcons {
    font-size: 24px;
}

.removeButton {
    background-color: var(--third-blue);
    color: var(--white-color);
    margin-top: 5px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    width: 150px;
    font-weight: bold;
}

.removeButton:hover {
    background-color: var(--white-color);
    color: var(--primary-blue);
}