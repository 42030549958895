@media screen and (max-width: 800px) {
    .desktopContainer {
        display: none;
    }
}

@media screen and (min-width: 800px) {
    .mobileContainer {
        display: none;
    }
}

.navigationTop {
    background-color: var(--primary-blue);
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
}

.socialLinks {
    z-index: 2;
}

.socialLink {
    width: 40px;
    height: 40px;
    margin-top: 5px;
    font-size: 28px;
}

.socialLink:hover {
    color: var(--yellow-color);
}

.navigationBottom {
    background-color: var(--primary-blue);
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.navigationLink {
    font-size: 18px;
    height: 50px;
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navigationLink:hover {
    border-bottom: var(--yellow-color) solid 3px;
    color: var(--yellow-color);
}

.mossbankLogo {
    z-index: 1;
}

.linkIcons {
    width: 30px;
    height: 30px;
}

.mobileNavigation {
    background-color: var(--primary-blue);
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navBars {
    width: 30px;
    height: 30px;
    margin-top: 2px;
    font-size: 28px;
}

.navBars:hover {
    color: var(--yellow-color);
}

.mobileIcons {
    display: flex;
    width: 100px;
    flex-direction: row;
    justify-content: space-evenly;
}