.container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 20px 2rem 20px 2rem;
    background-color: var(--white-color);
}

.locationContainer {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 50px 2rem 50px 2rem;
    background-color: var(--white-color);
}

.locationHeader {
    text-align: center;
    color: var(--primary-blue);
}

.buttonContainer {
    width: 400px
}

.ticket-card {
    min-width: 25%;
}

.closedTickets {
    margin-top: 20px;
    text-align: center;
    color: var(--primary-blue);
}

.buttonLink {
    max-width: 60%;
    margin-bottom: 20px;
}

.buttonLinkContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}