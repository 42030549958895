@media screen and (min-width: 800px) {
    .container {
        background-color: var(--primary-blue);
        color: #ffffff;
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        min-width: 200px;
    }
    .container:hover {
        background-color: #ffffff;
        color: var(--primary-blue);
    }
}

@media screen and (max-width: 800px) {
    .container {
        background-color: var(--primary-blue);
        color: #ffffff;
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        min-width: 75px;
        margin: 5px;
    }
    .title {
        font-size: 12px;
    }
    .container:hover {
        background-color: #ffffff;
        color: var(--primary-blue);
    }
}