html,
body {
    padding: 0;
    margin: 0;
    font-family: IBMPlexSans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    min-height: 1oovh;
    color: var(--white-color);
    background-color: var(--white-color);
    background-size: cover;
    background-attachment: fixed;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

:root {
    --primary-blue: #068ACD;
    --secondary-blue: #0071BB;
    --third-blue: #22AAE2;
    --text-black: #000000;
    --white-color: white;
    --orange-color: #E78C25;
    --yellow-color: #FDC226;
    --pink-color: #EC008D;
}

p {
    font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
    margin: 0;
}

.page-container {
    padding: 40px 11%;
}

.full-page-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 400px) {
    .footer {
        background-color: var(--primary-blue);
        padding: 10px 20px;
        text-align: center;
        margin-top: auto;
        width:100%;
    }
}

@media screen and (max-width: 400px) {
    .footer {
        background-color: var(--primary-blue);
        padding: 10px 20px;
        text-align: center;
        margin-top: auto;
        width: 100%;
    }
    .footer h3 {
        font-size: 16px;
        }
    .footer p {
        font-size: 12px;
    }
}