@media screen and (min-width: 800px) {
    .container {
        display: flex;
        flex-direction: row;
        background-color: var(--primary-blue);
        color: #ffffff;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        width: 100%;
        margin-bottom: 30px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .item {
        width: 25%;
        display: flex;
        flex-direction: column;
    }
    .quantity {
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .quantityIcons {
        margin: 0 10px;
        font-size: 25px;
    }
    .price {
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .total {
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .removeButton {
        background-color: var(--third-blue);
        color: var(--white-color);
        margin-top: 5px;
        padding: 10px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        width: 150px;
        font-weight: bold;
    }
    .removeButton:hover {
        background-color: var(--white-color);
        color: var(--primary-blue);
    }
    .ticketImage {
        width: 100px;
    }
}

@media screen and (max-width: 800px) {
    .container {
        display: flex;
        flex-direction: row;
        background-color: var(--primary-blue);
        color: #ffffff;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        width: 100%;
        margin-bottom: 30px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .item {
        width: 25%;
        display: flex;
        flex-direction: column;
        font-size: 12px;
    }
    .quantity {
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        font-size: 12px;
    }
    .quantityIcons {
        margin: 0 5px;
        font-size: 15px;
    }
    .price {
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        font-size: 12px;
    }
    .total {
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        font-size: 12px;
    }
    .removeButton {
        background-color: var(--third-blue);
        color: var(--white-color);
        margin-top: 5px;
        padding: 5px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        min-width: 50px;
        font-weight: bold;
        font-size: 12px;
    }
    .removeButton:hover {
        background-color: var(--white-color);
        color: var(--primary-blue);
    }
    .ticketImage {
        width: 50px;
    }
}