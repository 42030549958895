@media screen and (max-width: 800px) {
    .container {
        position: absolute;
        width: 240px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        border: 1px solid black;
        background-color: var(--white-color);
        top: 50px;
        right: 0%;
        z-index: 5;
    }
}

@media screen and (min-width: 800px) {
    .container {
        position: absolute;
        width: 240px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        border: 1px solid black;
        background-color: var(--white-color);
        top: 100px;
        right: 23%;
        z-index: 5;
    }
}

.cartItems {
    display: flex;
    flex-direction: column;
}

.emptyMessage {
    margin-bottom: 20px;
    color: black;
}

.title {
    color: black;
}